import {CSSProperties, Dispatch, ReactNode} from 'react'
import {Box, IconButton, Stack, useTheme} from '@mui/material'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import CuiResizable from 'src/components/shl/shared/CuiResizable'

interface SidePanelProps {
  openPanel: boolean
  setOpenPanel: Dispatch<React.SetStateAction<boolean>>
  children?: ReactNode
}

export default function SidePanel({
  openPanel,
  setOpenPanel,
  children
}: SidePanelProps) {
  const theme = useTheme()
  const floatingStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 999
  }

  return (
    <CuiResizable
      minWidth="20%"
      maxWidth="80%"
      maxHeight="100%"
      minHeight="100%"
      defaultSize={{height: '100%', width: '40%'}}
      enable={{left: true}}
      style={{
        height: '100%',
        display: openPanel ? 'flex' : 'none',
        ...floatingStyle
      }}
    >
      <Stack
        boxShadow={10}
        bgcolor={theme.palette.grey[200]}
        ml={1}
        mt={6}
        height="100%"
        width="100%"
        overflow="auto"
      >
        <Box mt={1} position="fixed" zIndex={999} right={0}>
          <IconButton onClick={() => setOpenPanel(false)}>
            <ClearRoundedIcon />
          </IconButton>
        </Box>
        {children}
      </Stack>
    </CuiResizable>
  )
}
