import {IconButton, Stack, Tooltip} from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import PostAddIcon from '@mui/icons-material/PostAdd'
import {useNavigate} from 'react-router'
import EditIconButton from 'src/components/shl/shared/EditIconButton'
import Examination from 'src/entities/Examination'

interface TestTrackActionColumnProp {
  examinationOrderId?: number
  currentRow: Examination
  setSelectedRow: (value: Examination) => void
  onOpenEditModal: (value: boolean) => void
  onOpenSidePanel: (value: boolean) => void
}

export default function TestTrackActionColumn({
  examinationOrderId,
  currentRow,
  setSelectedRow,
  onOpenEditModal,
  onOpenSidePanel
}: TestTrackActionColumnProp) {
  const navigate = useNavigate()

  return (
    <Stack direction="row">
      <Tooltip title="create a test for this examination">
        <IconButton
          onClick={() =>
            navigate(
              `/shl/tests/${examinationOrderId}/tracks/${currentRow.examinationID}`
            )
          }
        >
          <PostAddIcon />
        </IconButton>
      </Tooltip>
      <EditIconButton
        onClick={e => {
          e.stopPropagation()
          setSelectedRow(currentRow)
          onOpenEditModal(true)
        }}
      />
      <Tooltip title="responses overview">
        <span>
          <IconButton
            disabled={!currentRow.formID}
            onClick={() => {
              setSelectedRow(currentRow)
              onOpenSidePanel(true)
            }}
          >
            <BarChartIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  )
}
