import React, {useCallback, useEffect, useState} from 'react'
import {Link, Route, Routes, useParams} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {Box, Button, Stack, Tab, Tabs, Typography} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import config from 'src/config'
import Label from 'src/components/Label'
import TestTrackList from 'src/components/shl/tests/testTracks/TestTrackList'
import {useRouteMatch} from 'src/utils/routeHelper'
import {TestExamineeList} from 'src/components/shl/tests/testExaminees/TestExamineeList'
import {ExaminationOrder} from 'src/entities/ExaminationOrder'
import {useAuth} from 'src/contexts/Auth'
import {fDateTimeSuffix} from 'src/utils/formatTime'
import {
  convertFromApiToExaminationCycle,
  ExaminationCycleFromApi
} from 'src/components/shl/tests/testUtils'
import Form from 'src/entities/Form'

export default function TestPage() {
  const [test, setTest] = useState<ExaminationOrder>()
  const [isOpenAddExamineeDialog, setIsOpenAddExamineeDialog] =
    useState<boolean>(false)
  const [formsList, setFormsList] = useState<Form[]>()
  const {id} = useParams()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const routeMatch = useRouteMatch([
    '/shl/tests/:id/tracks',
    '/shl/tests/:id/examinees/*'
  ])
  const currentTab = routeMatch?.pattern?.path
  const TestExamineeListWithProps = (
    <TestExamineeList
      isOpenAddExamineeDialog={isOpenAddExamineeDialog}
      setIsOpenAddExamineeDialog={setIsOpenAddExamineeDialog}
      examinationOrder={test?.order}
      formsList={formsList}
    />
  )

  const TestTrackListWithProps = (
    <TestTrackList formsList={formsList} examinationOrder={test?.order} />
  )

  const getFormsList = useCallback(async () => {
    await fetchWithUser(
      `${config.apiUrl}/Form/GetFormListByExaminationOrderID/${id}`
    )
      .then(res => res.json())
      .then((data: Form[]) => {
        setFormsList(data)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }, [id, fetchWithUser, enqueueSnackbar])

  const getTest = useCallback(() => {
    fetchWithUser(config.apiUrl + `/Examination/GetExaminationOrder/${id}`)
      .then(res => res.json())
      .then((data: ExaminationCycleFromApi) => {
        setTest(convertFromApiToExaminationCycle(data))
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }, [id, fetchWithUser, enqueueSnackbar])

  useEffect(() => {
    getTest()
    getFormsList()
  }, [getTest, getFormsList])

  return (
    <Box sx={{width: '100%', height: '100%', px: 8}}>
      <Stack direction="row" alignItems="baseline" spacing={5}>
        <Typography fontWeight="bold" fontSize="large">
          Test {test?.order}
        </Typography>
        {test?.submissionTime && (
          <Typography fontSize="md">
            {fDateTimeSuffix(test.submissionTime)}
          </Typography>
        )}
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems="end"
        justifyContent="space-between"
        pr={2}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="end"
          justifyContent="space-between"
          pr={2}
        >
          <Tabs value={currentTab}>
            <Tab
              label="Tracks"
              value="/shl/tests/:id/tracks"
              to={`/shl/tests/${id}/tracks`}
              component={Link}
            />
            <Tab
              label="Examinees"
              value="/shl/tests/:id/examinees/*"
              to={`/shl/tests/${id}/examinees`}
              component={Link}
              icon={
                <Label color="default" variant="ghost" sx={{ml: 1}}>
                  {test?.totalExaminees}
                </Label>
              }
              iconPosition="end"
            />
          </Tabs>
        </Stack>
        {currentTab?.includes('examinees') && (
          <Button
            variant="contained"
            size="medium"
            sx={{height: 36, margin: 'auto', alignSelf: 'center'}}
            onClick={() => {
              setIsOpenAddExamineeDialog(true)
            }}
          >
            <AddIcon />
            Add Examinees
          </Button>
        )}
      </Stack>
      <Routes>
        <Route path="/tracks" element={TestTrackListWithProps} />
        <Route path="/examinees" element={TestExamineeListWithProps} />
        <Route path="/examinees/:trackId" element={TestExamineeListWithProps} />
        <Route
          path="/examinees/:trackId/:statusId"
          element={TestExamineeListWithProps}
        />
        <Route path="/*" element={TestTrackListWithProps} />
      </Routes>
    </Box>
  )
}
