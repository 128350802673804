import {useLocation, useParams} from 'react-router-dom'
import React, {useCallback, useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {Stack, Typography} from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridToolbarQuickFilter,
  GridValueGetterParams,
  useGridApiRef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import {useAuth} from 'src/contexts/Auth'
import {
  RaffleScreenEnum,
  GridColumnRaffleEventParticipants
} from 'src/components/shl/raffles/rafflesUtils'
import WinnersLotteryType from 'src/entities/WinnersLotteryType'
import EmployeeLottery from 'src/entities/EmployeeLottery'
import config from 'src/config'
import {globalSettingsColumnWithSort} from 'src/settings/type'
import CuiToolbar from 'src/components/custom/CuiToolbar'
import useInitalState from 'src/hooks/useInitalState'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import BabyIcon from 'src/images/babyIcon.svg'

export default function RaffleParticipantsPage() {
  const location = useLocation() as any
  const winners = location?.state?.winners
  const raffleName = location?.state?.raffleName
  const {id, type} = useParams()
  const winnersArray = winners as WinnersLotteryType[]
  const [raffleList, setRaffleList] = useState<WinnersLotteryType[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const apiRef = useGridApiRef()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {initialState} = useInitalState(
    RaffleScreenEnum.Raffles,
    apiRef.current?.exportState,
    apiRef.current?.restoreState,
    apiRef.current?.setColumnVisibilityModel
  )

  const columns: GridColDef[] = [
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEventParticipants.ParticipantId,
      headerName: 'Id',
      flex: 0.05,
      valueGetter: (params: GridValueGetterParams) => params.row.employeeID
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEventParticipants.ParticipantName,
      headerName: 'Name',
      flex: 0.15,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.lastName} ${params.row.firstName}`,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.lastName} {params.row.firstName}
          {type !== 'winners' &&
            winnersArray.some(
              element => element.employeeID === params.row.employeeID
            ) && <EmojiEventsIcon />}
          {params.row.isML && (
            <img
              style={{
                height: 20,
                width: 20,
                marginLeft: 4
              }}
              src={BabyIcon}
              alt=""
            />
          )}
        </>
      )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEventParticipants.ParticipantCity,
      headerName: 'City',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => params.row.city
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEventParticipants.ParticipantMail,
      headerName: 'Mail',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => params.row.mail
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEventParticipants.ParticipantLotteryTickets,
      headerName: 'Raffle Tickets',
      flex: 0.1
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEventParticipants.ParticipantOffice,
      headerName: 'Office',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => params.row.office
    }
  ]

  const GetRafflesTypeList = useCallback(() => {
    fetchWithUser(`${config.apiUrl}/LiveLottery/GetEmpoyeesByLottery/${id}`)
      .then(res => res.json())
      .then((data: EmployeeLottery[]) => {
        if (type === 'winners') {
          const filteredArray = data.filter(item =>
            winnersArray.some(element => element.employeeID === item.employeeID)
          )
          setRaffleList(filteredArray)
        } else {
          setRaffleList(data)
        }
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
      .finally(() => setLoading(false))
  }, [fetchWithUser, enqueueSnackbar, id, type, winnersArray])

  useEffect(GetRafflesTypeList, [GetRafflesTypeList])

  return (
    <Stack spacing={3} height="calc(100% - 84px)">
      <Typography fontWeight="bold" fontSize="x-large">
        {raffleName}
      </Typography>
      <Typography fontWeight="bold" fontSize="large">
        {type === 'winners' ? 'Winners' : 'Raffle Participants'}
      </Typography>
      <DataGridPro
        rows={raffleList}
        apiRef={apiRef}
        columns={columns}
        experimentalFeatures={{newEditingApi: true}}
        autoPageSize
        disableExtendRowFullWidth
        headerHeight={42}
        rowHeight={47}
        getRowId={row => row.employeeID}
        pageSize={30}
        loading={loading || !initialState}
        components={{
          Toolbar: () => (
            <Stack>
              <CuiToolbar
                screenId={RaffleScreenEnum.Raffles}
                exportState={apiRef.current.exportState}
                columnVisibilityModel={
                  apiRef.current.state?.columns.columnVisibilityModel
                }
                csvFileName={`winners `}
              />
              <GridToolbarQuickFilter
                placeholder="Search Participant"
                fullWidth
                variant="outlined"
                sx={{
                  mx: 2,
                  my: 1,
                  height: 52,
                  width: 'calc(100% - 48px)',
                  '.MuiFormControl-root': {
                    height: '100%'
                  },
                  '.MuiOutlinedInput-root': {
                    height: '100%'
                  }
                }}
              />
            </Stack>
          )
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            debounceMs: 300
          }
        }}
        sx={{
          '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
            background: '#F4F6F8',
            borderRadius: 1
          },
          '.MuiDataGrid-withBorder': {
            borderRight: 'none'
          }
        }}
      />
    </Stack>
  )
}
