import {Dispatch} from 'react'
import {TestResults} from '@madisoncres/cb-form-creator'
import SidePanel from 'src/components/shl/shared/SidePanel'
import Examination from 'src/entities/Examination'
import Form from 'src/entities/Form'
import FormResult from 'src/entities/FormResult'

interface ResponsesOverviewModalProps {
  openSidePanel: boolean
  setOpenSidePanel: Dispatch<React.SetStateAction<boolean>>
  examination?: Examination
  form?: Form
  formResultsList?: FormResult[]
}

export function ResponsesOverviewModal({
  openSidePanel,
  setOpenSidePanel,
  examination,
  form,
  formResultsList
}: ResponsesOverviewModalProps) {
  return (
    <SidePanel openPanel={openSidePanel} setOpenPanel={setOpenSidePanel}>
      {!!examination?.baseExamination?.track ? (
        <TestResults
          track={examination?.baseExamination?.track}
          formContent={form?.content}
          formResultsList={formResultsList}
        />
      ) : (
        <></>
      )}
    </SidePanel>
  )
}
