import {Dispatch} from 'react'
import {alpha, Stack, Typography} from '@mui/material'
import {UserTest} from '@madisoncres/cb-form-creator'
import Track from 'src/entities/Track'
import {
  EmployeeToExamination,
  SubmissionStatusEnum
} from 'src/entities/EmployeeToExamination'
import SidePanel from 'src/components/shl/shared/SidePanel'

interface ExamineesResultModalProps {
  openPanel: boolean
  setOpenPanel: Dispatch<React.SetStateAction<boolean>>
  selectedEmployee?: EmployeeToExamination
  formContent?: string
}

export function ExamineesResultModal({
  openPanel,
  setOpenPanel,
  selectedEmployee,
  formContent
}: ExamineesResultModalProps) {
  return (
    <SidePanel openPanel={openPanel} setOpenPanel={setOpenPanel}>
      {selectedEmployee?.submissionStatus === SubmissionStatusEnum.Lack ? (
        <Stack alignItems="center" width="100%" mt={20}>
          <Typography
            width="fit-content"
            variant="subtitle1"
            borderRadius={3}
            textAlign="center"
            p={3}
            border={2}
            borderColor={alpha(
              selectedEmployee?.examination?.baseExamination.track
                ?.primeColor ?? '#ffff',
              0.2
            )}
          >
            {`${selectedEmployee.employee?.firstName} ${selectedEmployee.employee?.lastName} `}
            still hasn't answered this test
          </Typography>
        </Stack>
      ) : !formContent ? (
        <></>
      ) : (
        <>
          <UserTest
            testContent={formContent}
            track={
              selectedEmployee?.examination?.baseExamination.track ??
              ({} as Track)
            }
            testResult={selectedEmployee?.formResult?.content ?? ''}
            isPassingScore={selectedEmployee?.isPassingScore}
          />
          <Stack
            justifyContent="center"
            direction="row"
            bottom={0}
            position="fixed"
            zIndex={999}
          >
            <Typography
              variant="subtitle1"
              p={1}
              bgcolor={alpha(
                selectedEmployee?.examination?.baseExamination.track
                  ?.primeColor ?? '#ffff',
                0.5
              )}
            >
              {`${selectedEmployee?.employee?.firstName} ${selectedEmployee?.employee?.lastName}'s test`}
            </Typography>
          </Stack>
        </>
      )}
    </SidePanel>
  )
}
